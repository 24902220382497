import weddingImage from './fannysamir.jpg';
import React, { useState } from 'react';
import Confetti from 'react-confetti';
import './App.css';

function Celebration() {
  const [showConfetti] = useState(true);

  return (
    <div>
      {showConfetti && <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        numberOfPieces={200}
        colors={['#DA70D6', '#AAAADD']}
        gravity={0.03}
      />}
      <Message />
    </div>
  );
}

function Message() {
  const [showContent, setShowContent] = useState(false);
  const [isImageFading, setIsImageFading] = useState(false);

  const handleImageClick = () => {
    setIsImageFading(true);
    setTimeout(() => {
      setShowContent(true);
    }, 500);
  };

  const containerStyle = {
    fontFamily: 'Times New Roman, serif',
    fontSize: 'clamp(16px, 3vw, 24px)',
    width: '90%',
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'left',
    color: '#2F3105',
    padding: '20px'
  };

  return (
    <div className="message" style={containerStyle}>
      {!showContent && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
          opacity: isImageFading ? 0 : 1,
          transition: 'opacity 0.5s ease-out',
          width: '100%'
        }}>
          <img 
            src={weddingImage}
            alt="Wedding announcement" 
            onClick={handleImageClick}
            style={{ 
              width: '100%',
              maxWidth: '600px',
              height: 'auto',
              marginBottom: '20px'
            }} 
          />
        </div>
      )}
      
      {showContent && (
        <div style={{
          opacity: 1,
          transition: 'opacity 0.5s ease-in',
          padding: '0 15px'
        }}>
          <p>Vi ska gifta oss!</p>
          <p>I mitten av augusti är det äntligen dags. Vi tänkte fira i dagarna tre på södra Gotland och vill inget hellre än att ha dig med.</p>
          <p>Bröllopet äger rum den 16 augusti men vill du vara med på allt kul vi har planerat bör du räkna med att stanna på Gotland 15 - 17 augusti.</p>
          <p>PS. På fredagen är barn välkomna men lördag-söndag får de stanna hemma :)</p>
          <p>Massa kärlek,<br/>Fanny & Samir</p>
        </div>
      )}
    </div>
  );
}

export default Celebration;
